<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div class="rounded-t bg-white mb-0 px-6 py-6">
                <div class="text-center flex justify-between">
                    <h6 class="text-blueGray-700 text-xl font-bold">New Company</h6>
                    
                </div>
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form @submit="save">
                    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                        Company Information
                    </h6>
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="code">
                                    Code <span class="text-red-500">*</span>
                                </label>
                                <input type="text" name="code" v-model="code"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                     />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="name">
                                    Company Name <span class="text-red-500">*</span>
                                </label>
                                <input type="text" name="name" v-model="name"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                     />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="pic_name">
                                    PIC Name <span class="text-red-500">*</span>
                                </label>
                                <input type="text" name="pic_name" v-model="pic_name"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                     />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap">

                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="pic_phone">
                                    PIC Contact Number <span class="text-red-500">*</span>
                                </label>
                                <input type="text" name="pic_phone" v-model="pic_phone"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                     />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="group_code">
                                    Group Code <span class="text-red-500">*</span>
                                </label>
                                <input type="text" name="group_code" v-model="group_code"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                     />
                            </div>
                        </div>
                    </div>
                    <div class="w-full lg:w-12/12 px-4 mt-3 mb-6">
                        <button
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit">
                        Add
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            name: null,
            code: null,
            pic_name: null,
            pic_phone: null,
            group_code: null,
        }
    },
    methods: {
        save(form) {
            this.$Progress.start();
            form.preventDefault();
            let data = {
                name: this.name,
                code: this.code,
                pic_name: this.pic_name,
                pic_phone: this.pic_phone,
                group_code: this.group_code,
            }
            axios.post(process.env.VUE_APP_API_HOST + '/company', data, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                if (response.data.status == 200 || response.data.status == 201) {
                    let alert = JSON.stringify({ title: "Success", message: response.data.message });
                    localStorage.setItem("message_alert", alert);
                    this.$router.push("/admin/company/detail/" + response.data.data.id)
                } else {
                    this.$Progress.fail();
                    let alert = JSON.stringify({ title: "Failed", message: response.data.message, errors: response.data.detail });
                    localStorage.setItem("message_alert", alert);
                    this.$emit('triggerAlert', alert);
                }
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });

            }).catch(error => {
                this.$Progress.fail();
                let alert;
                if (error.response) {
                    alert = JSON.stringify({ title: "Failed", message: error.response.data.message, errors: error.response.data.detail });
                } else {
                    alert = JSON.stringify({ title: "Failed", message: error.message });
                }
                localStorage.setItem("message_alert", alert);
                this.$emit('triggerAlert', alert);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        },

    }
};
</script>