<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button" v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')">
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <router-link
        class="md:block text-center md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        to="/admin/company">
        Provis | Polismall
      </router-link>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <user-dropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="collapseShow">
        <!-- Collapse header -->
        <div class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/">
                Provis | Polismall
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- Form -->
        <form class="mt-6 mb-4 md:hidden">
          <div class="mb-3 pt-0">
            <input type="text" placeholder="Search"
              class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal" />
          </div>
        </form>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6 class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
          EMPLOYEE MANAGEMENT
        </h6>
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link
              :to="permissions.includes('superadmin_access')?'/admin/company':'/admin/company/detail/'+company_id"
              v-slot="{ href, navigate }">
              <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="company_active 
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500'
              ">
                <i class="fas fa-tools mr-2 text-sm" :class="company_active ? 'opacity-75' : 'text-blueGray-300'"></i>
                COMPANY
              </a>
            </router-link>
          </li>

          <li class="items-center" :class="permissions.includes('superadmin_access')?'':'hidden'">
            <router-link to="/admin/product" v-slot="{ href, navigate }">
              <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="
                product_active
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500'
              ">
                <i class="fas fa-table mr-2 text-sm" :class="product_active ? 'opacity-75' : 'text-blueGray-300'"></i>
                PRODUCT
              </a>
            </router-link>
          </li>

          <li class="items-center">
            <router-link to="/admin/employee" v-slot="{ href, navigate }">
              <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="employee_active
              ? 'text-emerald-500 hover:text-emerald-600'
              : 'text-blueGray-700 hover:text-blueGray-500'">
                <i class="fas fa-map-marked mr-2 text-sm"
                  :class="employee_active ? 'opacity-75' : 'text-blueGray-300'"></i>
                EMPLOYEE
              </a>
            </router-link>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" :class="permissions.includes('superadmin_access')?'':'hidden'" />
        <!-- Heading -->
        <h6 :class="permissions.includes('superadmin_access')?'':'hidden'"
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
          FEATURES
        </h6>
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4"
          :class="permissions.includes('superadmin_access')?'':'hidden'">
          <li class="items-center">
            <router-link to="/admin/user" v-slot="{ href, navigate }">
            <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="user_active
                          ? 'text-emerald-500 hover:text-emerald-600'
                          : 'text-blueGray-700 hover:text-blueGray-500'">
              <i class="fas fa-map-marked mr-2 text-sm" :class="user_active ? 'opacity-75' : 'text-blueGray-300'"></i>
                USER
              </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
); }

<script>
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";

export default {
  data() {
    return {
      collapseShow: "hidden",
      permissions: [],
      company_id: null,
      path: null,
      company_active: false,
      product_active: false,
      employee_active: false,
      user_active: false,
    };
  },
  methods: {
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
    loadFirst: function () {
      const token = localStorage.getItem("token");
      const parsedToken = this.parseJwt(token);
      this.permissions = parsedToken.permissions;
      this.company_id = parsedToken.comId;
      this.path = this.$route.path;
      if (this.path.includes("admin/company")) {
        this.company_active = true
      } else {
        this.company_active = false
      }
      if (this.path.includes("admin/employee")) {
        this.employee_active = true
      } else {
        this.employee_active = false
      }
      if (this.path.includes("admin/product")) {
        this.product_active = true
      } else {
        this.product_active = false
      }
      if (this.path.includes("admin/user")) {
        this.user_active = true
      } else {
        this.user_active = false
      }
    },
    parseJwt: function (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
  },
  components: {
    UserDropdown,
  },
  mounted() {
    this.loadFirst()
  },
  watch: {
    $route() {
      this.loadFirst()
    }
  }
};
</script>
