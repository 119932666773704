<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full px-4">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">{{editText}} Company Detail</h6>

            </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit="update">
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                    Company Information
                </h6>
                <div class="flex flex-wrap">
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="code">
                                Code <span class="text-red-500">*</span>
                            </label>
                            <input disabled="true" type="text" name="code" v-model="code"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                 />
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="name">
                                Company Name <span class="text-red-500">*</span>
                            </label>
                            <input disabled="true" type="text" name="name" v-model="name"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                 />
                        </div>
                    </div>
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="pic_name">
                                PIC Name <span class="text-red-500">*</span>
                            </label>
                            <input :disabled='isDisabled' type="text" name="pic_name" v-model="pic_name"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                 />
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap">

                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="pic_phone">
                                PIC Contact Number <span class="text-red-500">*</span>
                            </label>
                            <input :disabled='isDisabled' type="text" name="pic_phone" v-model="pic_phone"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                 />
                        </div>
                    </div>
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="group_code">
                                Group Code <span class="text-red-500">*</span>
                            </label>
                            <input :disabled='isDisabled' type="text" name="group_code" v-model="group_code"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                 />
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4 mt-3 mb-6"  :hidden="permissions.includes('superadmin_access')?false:true">
                    <button @click="edit(this)"
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit">
                        {{ editButtonText }}
                    </button>
                    <button :hidden='isDisabled' @click="edit(this)"
                        class="bg-blueGray-600 text-white active:bg-grey-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="w-full mb-12 px-4" :hidden="permissions.includes('superadmin_access')?false:true">
    <employee-table :company_id="this.$route.params.id" />
    
</div>
</div>
</template>

<script>
import EmployeeTable from "@/components/EmployeeTable.vue";
import axios from 'axios'

export default {
    data() {
        return{
            editButtonText: "Edit",
            editText: "",
            permissions: [], 
            name: null,
            code: null,
            pic_name: null,
            pic_phone: null,
            group_code: null,
        }
    },
    components: {
        EmployeeTable,
    },
    methods: {
        edit() {
            if (this.editButtonText == "Save") {
                this.editButtonText = "Edit";
                this.editText = "";
                this.loadPage();
            } else {
                this.editButtonText = "Save";
                this.editText = "Edit";
            }
        },
        loadPermission: function () {
            const token = localStorage.getItem("token");
            const parsedToken = this.parseJwt(token);
            this.permissions = parsedToken.permissions;
        },
        parseJwt: function (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        },
        update(form) {
            form.preventDefault();
            if (this.editButtonText == "Edit") {
                this.$Progress.start();
                let data = {
                    name: this.name,
                    code: this.code,
                    pic_name: this.pic_name,
                    pic_phone: this.pic_phone,
                    group_code: this.group_code,
                }
                let id = this.$route.params.id;
                axios.put(process.env.VUE_APP_API_HOST + '/company/' + id, data, {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem("token")
                    }
                }).then((response) => {
                    if (response.data.status == 200 || response.data.status == 201) {
                        let alert = JSON.stringify({ title: "Success", message: response.data.message });
                        localStorage.setItem("message_alert", alert);
                        this.$router.go(0);
                    } else {
                        this.$Progress.fail();
                        let alert = JSON.stringify({ title: "Failed", message: response.data.message, errors: response.data.detail });
                        localStorage.setItem("message_alert", alert);
                        this.$emit('triggerAlert', alert);
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                }).catch(error => {
                    this.$Progress.fail();
                    let alert;
                    if (error.response) {
                        alert = JSON.stringify({ title: "Failed", message: error.response.data.message, errors: error.response.data.detail });
                    } else {
                        alert = JSON.stringify({ title: "Failed", message: error.message });
                    }
                    localStorage.setItem("message_alert", alert);
                    this.$emit('triggerAlert', alert);
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                });
            }
        },
        loadPage() {
            let id = this.$route.params.id;
            axios.get(process.env.VUE_APP_API_HOST + '/company/' + id, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                this.name = response.data.data.name;
                this.code = response.data.data.code;
                this.pic_name = response.data.data.pic_name;
                this.pic_phone = response.data.data.pic_phone;
                this.group_code = response.data.data.group_code;
            })
            this.loadPermission();
        }
    },
    computed: {
        isDisabled() {
            if (this.editButtonText == "Save") {
                return false
            } else {
                return true
            }
            // return yourCondition == true
        },
    },
    mounted() {
        this.loadPage()
    }
};
</script>