<template>
  <div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            User List
          </h3>
        </div>
        <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
          <router-link to="/admin/user/insert" v-slot="{ href }">
            <a :href="href">
              <button
                class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button">
                Add New
              </button></a>
          </router-link>
        </div>
      </div>
      <div class="flex flex-wrap items-center mt-10">
        <div class="px-4 md:w-8/12">
          <form class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3" @submit="searchForm">
            <div class="relative mb-3 min-w-48 lg:mr-1">
              <input type="text" name="search" v-model="search"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Search User" />
            </div>
            <div class="relative mb-3">
              <span
                class="z-10 h-full leading-snug font-normal absolute text-center text-white absolute bg-transparent rounded text-base items-center justify-center w-8 py-2 pl-3">
                <i class="fas fa-search"></i>
              </span>
              <button
                class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-3 pl-10 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Username
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Name
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Email
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Last Login
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in api_data" :key="key">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{item.username}}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{item.name}}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{item.email}}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ item.last_login?moment(item.last_login).add(7, 'hour').format('MMMM Do YYYY, h:mm:ss a'):"-"}}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
              <user-table-dropdown :userid="item.id" />
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <div class="py-2 p-4">
                <nav class="block">
                  <ul class="flex pl-0 rounded list-none flex-wrap">
                    <li class="ml-1" :hidden="page_attr.page == 1 ? true : page_attr.page >5?false:true">
                      <router-link :to="addQuery({ page: 1 })" v-slot="{ href }">
                        <a :href=href
                          class="text-emerald-600 bg-white first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500">
                          &lt;&lt;
                        </a>
                      </router-link>
                    </li>
                    <li class="ml-1" v-for="index in page_attr.total_page" :key="index"
                      :hidden="index > page_attr.page + (index < 10 ? 10 : 4) ? true : index < page_attr.page - (index > page_attr.total_page-9 ? 10 : 4) ?true:false">
                      <router-link :to="addQuery({page:index})" v-slot="{ href }">
                        <a :href=href
                          class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500"
                          :class="index==page_attr.page?'text-white bg-emerald-500': 'text-emerald-600 bg-white'">
                          {{index}}
                        </a>
                      </router-link>
                    </li>
                    <li class="ml-1"
                      :hidden="page_attr.page == page_attr.total_page ? true : page_attr.page < page_attr.total_page-4?false:true">
                      <router-link :to="addQuery({ page: page_attr.total_page })" v-slot="{ href }">
                        <a :href=href
                          class="text-emerald-600 bg-white first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500">
                          &gt;&gt;
                        </a>
                      </router-link>
                    </li>
                    <li class="ml-1">
                      <span class="text-xs font-semibold inline-block py-1 px-2 last:mr-0 mr-1">Showing
                        {{page_attr.from}} to
                        {{page_attr.to}} data of {{page_attr.total_data}} results.</span>
                    </li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import UserTableDropdown from "@/components/Dropdowns/UserTableDropdown.vue";
import moment from 'moment';
import axios from 'axios'

export default {
  data() {
    return {
      api_data: null,
      page_attr: { from: null, to: null, total_page: null, total_data: null, page: null, page_size: null },
    };
  },
  components: {
    UserTableDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    searchForm(form) {
      form.preventDefault();
      let query = this.$route.query;
      query = {
        ...query,
      }
      if (this.search != null && this.search != "") {
        query.search = this.search;
      } else {
        delete query.search
      }
      query.page = 1;
      this.$router.push(this.$route.path + "?" + new URLSearchParams(query).toString())
      this.loadPage()
    },
    addQuery(q) {
      let query = this.$route.query;
      query = { ...query, ...q };
      let result = '?' + new URLSearchParams(query).toString();
      return result;
    },
    loadPage() {
      let query = this.$route.query;
      axios.get(process.env.VUE_APP_API_HOST + '/login/list', {
        params: query,
        headers: {
          'Authorization': `Bearer ` + localStorage.getItem("token")
        }
      }).then((response) => {
        this.api_data = response.data.data;
        this.page_attr.total_data = response.data.total_data;
        this.page_attr.page = response.data.page;
        this.page_attr.page_size = response.data.page_size;
        this.page_attr.from = response.data.from;
        this.page_attr.to = response.data.to;
        this.page_attr.total_page = response.data.total_page;
      })
    },
    moment: function (time) {
      return moment(time);
    }
  },
  mounted() {
    this.loadPage()
  },
  watch: {
    $route(r) {
      if (r.path.includes("/admin/user")) {
        this.loadPage();
      }
    }
  }
};
</script>
