<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Sign in with credentials
              </h6>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit="login">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="username"
                >
                  Username
                </label>
                <input
                  type="username" name="username" v-model="username"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Username"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  type="password" name="password" v-model="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                />
                <small class="text-left leading-relaxed mt-0 mb-0 font-normal leading-normal text-red-500" v-if="errors.length">
                  <b class="text-left">Please correct the following error(s):</b>
                  <ul>
                    <li v-for="error in errors" :key="error">- {{ error }}</li>
                  </ul>
                </small>
              </div>
              <div class="text-center mt-6">
                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Sign In
                  </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      errors: [],
      username: null,
      password: null,
    }
  },
  methods: {
    login(form) {
      this.errors = [];
      if (!this.username) {
        this.errors.push('Name required.');
      }
      if (!this.password) {
        this.errors.push('Password required.');
      }
      form.preventDefault();
      if (this.username && this.password) {
        let data = {
          username: this.username,
          password: this.password,
        }
        axios.post(process.env.VUE_APP_API_HOST + '/login', data).then((response) => {
          localStorage.setItem("token", response.data.data.accessToken);
          localStorage.setItem("username", this.username);
          window.location.href = "/admin/company";
        }).catch(() => {
          this.errors.push('Login failed, Please check your username or password');
        })
      }
    },

  }
};
</script>