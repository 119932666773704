<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
      @blur="closeDropdown"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link :to="'/admin/product/detail/'+productid" v-slot="{ href }">
        <a :href="href" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
          Detail Product
        </a>
      </router-link>
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <a
        href="javascript:void(0);"
        @click="deletePopup($event)"
        class="text-sm py-2 px-4 font-semibold block w-full whitespace-nowrap bg-transparent text-red-500"
      >
        Delete
      </a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import axios from 'axios'

export default {
  props: ['productid'],
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    deletePopup: function () {
      if (confirm("Are you sure want to delete this data")) {
        axios.delete(process.env.VUE_APP_API_HOST + '/product/' + this.productid, {
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem("token")
          }
        }).then((response) => {
          let alert = JSON.stringify({ title: "Success", message: response.data.message });
          localStorage.setItem("message_alert", alert);
          this.$router.go(0);
        }).catch(error =>  {
          if (error.response) {
            this.$Progress.fail();
let alert = JSON.stringify({ title: "Failed", message: error.response.data.message });
            localStorage.setItem("message_alert", alert);
          } else {
            this.$Progress.fail();
let alert = JSON.stringify({ title: "Failed", message: error.message });
            localStorage.setItem("message_alert", alert);
          }
        });
      }
    },
    documentClick(e){
      if (!this.$el.contains(e.target)) {
        this.dropdownPopoverShow = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.documentClick)
  },
  beforeUnmount() {
    // important to clean up!!
    document.removeEventListener('click', this.documentClick)
      
    },
};
</script>
