<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Product List
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
        <router-link to="/admin/product/insert" v-slot="{ href }">
          <a :href="href">
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            Add New
          </button></a></router-link>
        </div>
      </div>
      <div class="flex flex-wrap items-center mt-10">
        <div class="px-4 md:w-8/12">
          <form class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3" @submit="searchForm">
            <div class="relative mb-3 min-w-48 lg:mr-1">
              <input type="text" name="search" v-model="search"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Search Product" />
            </div>
            <div class="relative mb-3">
              <span
                class="z-10 h-full leading-snug font-normal absolute text-center text-white absolute bg-transparent rounded text-base items-center justify-center w-8 py-2 pl-3">
                <i class="fas fa-search"></i>
              </span>
              <button
                class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-3 pl-10 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit">
                Search
              </button>
            </div>
          </form>
        </div>
        <div class="px-4 md:w-4/12 text-right">
          <div class="relative mb-3 lg:ml-auto">
            <div class="relative w-full mb-3 lg:mr-1">
              <input ref="file" type="file" class="hidden" @change="importData" />
              <a href="#" @click="selectFile"><span
                  class="z-10 h-full leading-snug font-normal absolute text-center absolute bg-transparent rounded text-base items-center justify-center w-8 py-1 pl-3"
                  :class="isImport? 'text-white':'text-emerald-800'">
                  <i class="fas" :class="isImport ? 'fa-spinner' :'fa-file-excel'"></i>
                </span>
                <button
                  class="text-xs font-bold uppercase pl-10 px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  :class="isImport ? 'bg-gray-200 border border-solid border-gray-300 text-white' :'bg-transparent border border-solid border-emerald-700 text-emerald-800'"
                  type="button">
                  Import
                </button>
              </a>
              <a href="#" @click="exportData"><span
                  class="z-10 h-full leading-snug font-normal absolute text-center text-white absolute bg-transparent rounded text-base items-center justify-center w-8 py-1 pl-3">
                  <i class="fas fa-download"></i>
                </span>
                <button
                  class="bg-emerald-800 text-white active:bg-emerald-900 text-xs font-bold uppercase pl-10 px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button">
                  Download
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right py-2 px-6">
        <a href="#" @click="downloadTemplate">
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-800 bg-emerald-200 uppercase last:mr-0 mr-1">
            <i class="fas fa-file-excel"></i> Import template file
          </span>
        </a>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Code
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Product Name
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Description
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in api_data" :key="key">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{item.product_code}}
            </td>
            <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
            {{item.product_name}}
          </td>
          <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{item.product_description}}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <product-table-dropdown :productid="item.id" />
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <div class="py-2 p-4">
                <nav class="block">
                  <ul class="flex pl-0 rounded list-none flex-wrap">
                    <li class="ml-1" :hidden="page_attr.page == 1 ? true : page_attr.page >5?false:true">
                      <router-link :to="addQuery({ page: 1 })" v-slot="{ href }">
                        <a :href=href
                          class="text-emerald-800 bg-white first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500">
                          &lt;&lt;
                        </a>
                      </router-link>
                    </li>
                    <li class="ml-1" v-for="index in page_attr.total_page" :key="index"
                      :hidden="index > page_attr.page + (index < 10 ? 10 : 4) ? true : index < page_attr.page - (index > page_attr.total_page-9 ? 10 : 4) ?true:false">
                      <router-link :to="addQuery({page:index})" v-slot="{ href }">
                        <a :href=href
                          class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500"
                          :class="index==page_attr.page?'text-white bg-emerald-500': 'text-emerald-800 bg-white'">
                          {{index}}
                        </a>
                      </router-link>
                    </li>
                    <li class="ml-1"
                      :hidden="page_attr.page == page_attr.total_page ? true : page_attr.page < page_attr.total_page-4?false:true">
                      <router-link :to="addQuery({ page: page_attr.total_page })" v-slot="{ href }">
                        <a :href=href
                          class="text-emerald-800 bg-white first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500">
                          &gt;&gt;
                        </a>
                      </router-link>
                    </li>
                    <li class="ml-1">
                      <span class="text-xs font-semibold inline-block py-1 px-2 last:mr-0 mr-1">Showing {{page_attr.from}} to
                        {{page_attr.to}} data of {{page_attr.total_data}} results.</span>
                    </li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import ProductTableDropdown from "@/components/Dropdowns/ProductTableDropdown.vue";
import { useLoading } from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

import axios from 'axios'

export default {
  data() {
    return {
      loader: useLoading(),
      isImport: false,
      api_data: null,
      page_attr: { from: null, to: null, total_page: null, total_data: null, page: null, page_size: null },
      search: null,
      product: null,
    };
  },
  components: {
    ProductTableDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    searchForm(form) {
      form.preventDefault();
      let query = this.$route.query;
      query = {
        ...query,
      }
      if (this.search != null && this.search != "") {
        query.product_name = this.search;
      } else {
        delete query.product_name
      }
      query.page = 1;
      this.$router.push(this.$route.path + "?" + new URLSearchParams(query).toString())
      this.loadPage()
    },
    addQuery(q) {
      let query = this.$route.query;
      query = { ...query, ...q };
      let result = '?' + new URLSearchParams(query).toString();
      return result;
    },
    loadPage() {
      let query = this.$route.query;
      axios.get(process.env.VUE_APP_API_HOST+'/product', {
        params: query,
        headers: {
          'Authorization': `Bearer `+localStorage.getItem("token")
        }
      }).then((response) => {
        this.api_data = response.data.data;
        this.page_attr.total_data = response.data.total_data;
        this.page_attr.page = response.data.page;
        this.page_attr.page_size = response.data.page_size;
        this.page_attr.from = response.data.from;
        this.page_attr.to = response.data.to;
        this.page_attr.total_page = response.data.total_page;
      })
    },
    exportData(e) {
      this.$Progress.start();
      e.preventDefault();
      let query = this.$route.query;
      axios.get(process.env.VUE_APP_API_HOST + '/product/export', {
        params: query,
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ` + localStorage.getItem("token")
        }
      }).then((response) => {
        this.$Progress.set(50);
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'product.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.$Progress.finish();
      })
    },
    selectFile(e) {
      e.preventDefault();
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    downloadTemplate(e) {
      this.$Progress.start();
      e.preventDefault();
      let data = process.env.VUE_APP_PRODUCT_TEMPLATE;
      const href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data;
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'product.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      this.$Progress.finish();
    },
    importData(e) {
      this.isImport = true;
      this.loader.show({
        color: "#6466f1",
        opacity: 0.3,
        backgroundColor: "#000",
      });
      let file = e.target.files;
      let formData = new FormData();
      formData.append('filename', file[0]);
      axios.post(process.env.VUE_APP_API_HOST + '/product/import',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ` + localStorage.getItem("token")
          }
        }).then((response) => {
          this.isImport = false;
          if (response.data.status !== 200) {
            let alert = JSON.stringify({ title: "Failed", message: response.data.message ? response.data.message : "Something went wrong" });
            localStorage.setItem("message_alert", alert);
            this.$router.go(0);
          } else {
            let alert = JSON.stringify({
              title: "Success",
              message: "Total row: " + response.data.data.total + " | Inserted: " + response.data.data.insert + " | Updated: " + response.data.data.update + " | Failed: " + response.data.data.failed,
              errors: response.data.data.failed_data
            });
            localStorage.setItem("message_alert", alert);
            this.$router.go(0);
          }
        }).catch(error => {
          let alert = JSON.stringify({ title: "Failed", message: error.message });
          localStorage.setItem("message_alert", alert);
          this.$router.go(0);
        });
    }
  },
  mounted() {
    this.loadPage()
  },
  watch: {
    $route(r) {
      if (r.path.includes("/admin/product")) {
        this.loadPage();
      }
    }
  }
};
</script>
