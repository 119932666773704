<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <company-table />
    </div>
  </div>
</template>
<script>
import CompanyTable from "@/components/CompanyTable.vue";

export default {
  components: {
    CompanyTable,
  },
};
</script>
