import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import moment from 'moment';
import VueProgressBar from "@aacassandra/vue3-progressbar";
// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";
// views for Admin layout

import Company from "@/views/admin/Company.vue";
import Product from "@/views/admin/Product.vue";
import ProductFormInsert from "@/views/admin/ProductFormInsert.vue";
import ProductDetail from "@/views/admin/ProductDetail.vue";
import CompanyFormInsert from "@/views/admin/CompanyFormInsert.vue";
import CompanyDetail from "@/views/admin/CompanyDetail.vue";
import EmployeeDetail from "@/views/admin/EmployeeDetail.vue";
import Employee from "@/views/admin/Employee.vue";
import EmployeeFormInsert from "@/views/admin/EmployeeFormInsert.vue";
import User from "@/views/admin/User.vue";
import UserDetail from "@/views/admin/UserDetail.vue";
import UserChangePassword from "@/views/admin/UserChangePassword.vue";
import UserFormInsert from "@/views/admin/UserFormInsert.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";

// views without layouts

// routes
const token = localStorage.getItem("token");
const routes = []
// routes.push({
  //         path: "/user/detail/:id",
  //         component: UserDetail,
  //       });
routes.push({ path: "/",
component: Auth, redirect:"/auth/login", children:[{path:"/auth/login", component: Login}]});
if (token) {
  const parsedToken = parseJwt(token);
  if (parsedToken.exp<moment().unix()) {
      localStorage.clear();
      window.location.href = "/";
  }
  const adminRoutes = { path: "/admin", component: Admin, children:[] }
  adminRoutes.children.push({
    path: "/admin/user/detail",
    component: UserDetail,
    redirect: "/admin/user/detail/"+parsedToken.sub,
  });
  adminRoutes.children.push({
    path: "/admin/user/detail/:id",
    component: UserDetail,
  });
  adminRoutes.children.push({
    path: "/admin/user/change-password",
    component: UserChangePassword,
    redirect: "/admin/user/change-password/"+parsedToken.sub,
  });
  adminRoutes.children.push({
    path: "/admin/user/change-password/:id",
    component: UserChangePassword,
  });
  if(parsedToken.role=="superadmin"){
    adminRoutes.children.push({
      path: "/admin/user/insert",
      component: UserFormInsert,
    });
    adminRoutes.children.push({
          path: "/admin/user",
          component: User,
        });
  }
  if(parsedToken.role=="superadmin" || parsedToken.permissions.includes("can_read_own_company") || parsedToken.permissions.includes("can_read_company") || parsedToken.permissions.includes("company_full_privilege")){
    let company = {
          path: "/admin/company",
          component: Company,
        }
    if(parsedToken.role!=="superadmin") {
      company.redirect = "/admin/company/detail/"+parsedToken.comId
    }
    adminRoutes.children.push(company);
  }
  if(parsedToken.role=="superadmin" || parsedToken.permissions.includes("can_create_company") || parsedToken.permissions.includes("company_full_privilege")){
    adminRoutes.children.push({
          path: "/admin/company/insert",
          component: CompanyFormInsert,
        });
  }
  if(parsedToken.role=="superadmin" || parsedToken.permissions.includes("can_read_own_company") || parsedToken.permissions.includes("can_edit_company") || parsedToken.permissions.includes("company_full_privilege")){
    adminRoutes.children.push({
          path: "/admin/company/detail/:id",
          component: CompanyDetail,
        });
  }
  
  if(parsedToken.role=="superadmin" || parsedToken.permissions.includes("can_read_employee") || parsedToken.permissions.includes("employee_full_privilege")){
    adminRoutes.children.push({
          path: "/admin/employee",
          component: Employee,
        });
  }
  if(parsedToken.role=="superadmin" || parsedToken.permissions.includes("can_create_employee") || parsedToken.permissions.includes("employee_full_privilege")){
    adminRoutes.children.push({
          path: "/admin/employee/insert",
          component: EmployeeFormInsert,
        });
  }
  if(parsedToken.role=="superadmin" || parsedToken.permissions.includes("can_edit_employee") || parsedToken.permissions.includes("employee_full_privilege")){
    adminRoutes.children.push({
          path: "/admin/employee/detail/:id",
          component: EmployeeDetail,
        });
  }
  
  if(parsedToken.role=="superadmin" || parsedToken.permissions.includes("can_read_product") || parsedToken.permissions.includes("product_full_privilege")){
    adminRoutes.children.push({
          path: "/admin/product",
          component: Product,
        });
  }
  if(parsedToken.role=="superadmin" || parsedToken.permissions.includes("can_create_product") || parsedToken.permissions.includes("product_full_privilege")){
    adminRoutes.children.push({
          path: "/admin/product/insert",
          component: ProductFormInsert,
        });
  }
  if(parsedToken.role=="superadmin" || parsedToken.permissions.includes("can_edit_product") || parsedToken.permissions.includes("product_full_privilege")){
    adminRoutes.children.push({
          path: "/admin/product/detail/:id",
          component: ProductDetail,
        });
  }
  routes.push(adminRoutes);
}
routes.push({ path: "/:pathMatch(.*)*", redirect: "/" });
const router = createRouter({
  history: createWebHistory(),
  routes,
});
let app = createApp(App)
app.config.globalProperties.$moment=moment;
app.use(VueProgressBar, {
  color: "#6466f1",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "15s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
  autoFinish: false,
}).use(router).mount("#app");
function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}