<template>
    <div>
    <div class="flex flex-wrap mt-4">
        <div class="w-full px-4">
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div class="rounded-t bg-white mb-0 px-6 py-6">
                    <div class="text-center flex justify-between">
                        <h6 class="text-blueGray-700 text-xl font-bold">New Employee</h6>
                    </div>
                </div>
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form @submit="save">
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            Employee Information
                        </h6>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Company Name <span class="text-red-500">*</span>
                                    </label>
                                    <select name="company_id" v-model="company_id" :disabled="disableChildrenStatus?'disabled':false"  @blur="itsChanged('company_id')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option v-for="(item, key) in company_dropdown_data" :key="key" :value="item.code" :selected="company_id">{{item.name}}</option>
                                    </select>
                                    <div class="text-red-500 text-right text-sm"><span>{{ company_id_error }}</span></div>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Policy Number <span class="text-red-500">*</span>
                                    </label>
                                    <input type="text" v-model="policy_number" name="policy_number"  @blur="itsChanged('policy_number')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    />
                                    <div class="text-red-500 text-right text-sm"><span>{{ policy_number_error }}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Member Status <span class="text-red-500">*</span>
                                    </label>
                                    <select v-model="member_status"  @blur="itsChanged('member_status')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option value="E" :disabled="parent_id?true:false">Employee</option>
                                        <option value="P" :disabled="parent_id ? marital_status == 'W' ? true : spouse==1?true:false:true">Spouse</option>
                                        <option value="K" :disabled="parent_id ?children<2?false:true:true">Children</option>
                                    </select>
                                    <div class="text-red-500 text-right text-sm"><span>{{ member_status_error }}</span></div>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Participant No
                                    </label>
                                    <input type="text" v-model="member_id"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Dependant Code (DepCode)
                                    </label>
                                    <input v-model="dep_code"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Dependant Type (DepType)
                                    </label>
                                    <input v-model="dep_type"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">

                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Employee Name <span class="text-red-500">*</span>
                                    </label>
                                    <input type="text" v-model="employee_name" :disabled="disableChildrenStatus?'disabled':false"  @blur="itsChanged('employee_name')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    />
                                    <div class="text-red-500 text-right text-sm"><span>{{ employee_name_error }}</span></div>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        NIK
                                    </label>
                                    <input type="text" v-model="nik" v-on:blur="formatInt('nik')" v-on:keyup="formatInt('nik')" 
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">

                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Employee Number
                                    </label>
                                    <input type="text" v-model="employee_number"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Certificate Number
                                    </label>
                                    <input type="text" v-model="certificate_number" name="certificate_number"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                        
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Insured Name <span class="text-red-500">*</span>
                                    </label>
                                    <input type="text" v-model="insured_name" name="insured_name"  @blur="itsChanged('insured_name')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    <div class="text-red-500 text-right text-sm"><span>{{ insured_name_error }}</span></div>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Date of Birth <span class="text-red-500">*</span>
                                    </label>
                                    <input type="date" v-model="dob" :max="$moment(Date.now()).format('YYYY-MM-DD')"  @blur="itsChanged('dob')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        />
                                    <div class="text-red-500 text-right text-sm"><span>{{ dob_error }}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                        
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Gender <span class="text-red-500">*</span>
                                    </label>
                                    <select v-model="gender"  @blur="itsChanged('gender')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                    <div class="text-red-500 text-right text-sm"><span>{{ gender_error }}</span></div>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Marital Status <span class="text-red-500">*</span>
                                    </label>
                                    <select v-model="marital_status"  @blur="itsChanged('marital_status')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option value="S" :disabled="member_status=='P'?true:false">Single</option>
                                        <option value="M">Married</option>
                                        <option value="D" :disabled="member_status=='P'?true:false">Divorced</option>
                                    </select>
                                    <div class="text-red-500 text-right text-sm"><span>{{ marital_status_error }}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Beneficiary Name
                                    </label>
                                    <input type="text" v-model="beneficiary_name"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Phone Number
                                    </label>
                                    <input type="text" v-model="phone_number" v-on:blur="formatInt('phone_number')" v-on:keyup="formatInt('phone_number')" 
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                        
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Bank Name
                                    </label>
                                    <input type="text" v-model="bank_name"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Bank Account Number
                                    </label>
                                    <input type="text" v-model="bank_account" v-on:blur="formatInt('bank_account')" v-on:keyup="formatInt('bank_account')" 
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Salary
                                    </label>
                                    <input type="text" v-model="salary" v-on:blur="formatCurrency('salary')" v-on:keyup="formatCurrency('salary')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                        Effective Date <span class="text-red-500">*</span>
                                    </label>
                                    <input type="date" v-model="effective_date"  @blur="itsChanged('effective_date')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    <div class="text-red-500 text-right text-sm"><span>{{ effective_date_error }}</span></div>
                                </div>
                            </div>
                            <!-- <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Movement History
                                    </label>
                                    <textarea type="text" v-model="movement_history"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                                                                -
                                                                            </textarea>
                                </div>
                            </div> -->
                        </div>
                        <div class="flex flex-wrap">
                        
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Employee Status
                                    </label>
                                    <select v-model="inactive_status" @change="inactive($event)"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        <option value="0">Active</option>
                                        <option value="1">Terminate</option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3" :hidden='isInactive'>
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Termination Date
                                    </label>
                                    <input type="date" v-model="inactive_date" :max="$moment(Date.now()).add(1, 'weeks').format('YYYY-MM-DD')"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                        Note
                                    </label>
                                    <textarea type="text" v-model="note"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                                                                                            -
                                                                                                        </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-12/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Product
                                    </label>
                                    <span v-for="(item, key) in productSelected" :key="key" @click="showModal(item.id, item.inieffdate)"
                                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full mb-1 uppercase last:mr-0 mr-1"
                                            :class="item.is_active ? 'text-white bg-lime-700' :'text-white bg-red-600'">
                                        <a href="javascript:void(0)">{{item.product_name}}</a>
                                    </span>

                                </div>
                                <div class="w-full lg:w-12/12 mt-3 mb-6">
                                    <button @click="showModal(null, null)"
                                        class="bg-blueGray-600 text-white active:bg-blueGray-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button">
                                        Add Product
                                    </button>
                                    <EmployeeModal v-show="isModalVisible" @close="closeModal" ref="modalProduct" :isdisable="disableeditmodal" @clicked="onClickChild" />
                                    <button @click="copyProduct" :hidden="parent_id?false:true"
                                        class="bg-indigo-500 text-white active:bg-indigo-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button">
                                        Copy from Employee
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                        <div class="w-full lg:w-12/12 px-4 mt-3 mb-6">
                            <button @click="close"
                                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import EmployeeModal from "@/components/Modal/EmployeeAddProduct.vue";
import axios from 'axios'

export default {
    data() {
        return {
            company_dropdown_data: null,
            company_id: null,
            policy_number: null,
            certificate_number: null,
            nik: null,
            parent_id: null,
            employee_name: null,
            member_status: 'E',
            insured_name: null,
            dob: null,
            gender: 'M',
            marital_status: 'S',
            movement_history: null,
            inactive_status: 0,
            inactive_date: null,
            phone_number: '',
            bank_name: null,
            bank_account: null,
            beneficiary_name: null,
            salary: null,
            employee_number: null,
            member_id: null,
            dep_code: null,
            dep_type: null,
            effective_date: null,
            note: null,
            isModalVisible: false,
            inactiveStatus: false,
            parent_name: null, 
            productSelected: [],
            employeeData:null,
            spouse:null,
            children:null,
            parent_product:null,
            disableChildrenStatus:false,
            employee_effective_date: null,
            disableeditmodal: false,
            company_id_error: '',
            policy_number_error: '',
            member_status_error: '',
            employee_name_error: '',
            insured_name_error: '',
            dob_error: '',
            gender_error: '',
            marital_status_error: '',
            effective_date_error: '',
        }
    },
    components: {
        EmployeeModal,
    }, 
    methods: {
        save(form) {
            form.preventDefault();
            let employeeData = {
                company_code: this.company_id,
                policy_number: this.policy_number,
                certificate_number: this.certificate_number,
                nik: this.nik,
                parent_id: this.parent_id,
                employee_name: this.employee_name,
                employee_status: this.member_status,
                insured_name: this.insured_name,
                insured_dob: this.dob,
                insured_gender: this.gender,
                marital_status: this.marital_status,
                history_movement: this.movement_history,
                inactive_status: this.inactive_status,
                inactive_date: this.inactive_date,
                phone: this.phone_number,
                bank_name: this.bank_name,
                bank_account_number: this.bank_account,
                bank_beneficiary: this.beneficiary_name,
                salary: this.salary,
                employee_number: this.employee_number,
                member_id: this.member_id,
                dep_code: this.dep_code,
                dep_type: this.dep_type,
                effective_date: this.effective_date,
                note: this.note,
                products: this.productSelected,
            }
            axios.post(process.env.VUE_APP_API_HOST + '/employee', employeeData, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                if (response.data.status == 200 || response.data.status == 201) {
                    let alert = JSON.stringify({ title: "Success", message: response.data.message });
                    localStorage.setItem("message_alert", alert);
                    this.$router.push("/admin/employee/detail/" + response.data.data.id)
                } else {
                    this.$Progress.fail();
                    let alert = JSON.stringify({ title: "Failed", message: response.data.message, errors: response.data.detail });
                    localStorage.setItem("message_alert", alert);
                    this.$emit('triggerAlert', alert);
                    this.formValidate();
                }
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }).catch(error => {
                this.$Progress.fail();
                let alert;
                if (error.response) {
                    alert = JSON.stringify({ title: "Failed", message: error.response.data.message, errors: error.response.data.detail });
                } else {
                    alert = JSON.stringify({ title: "Failed", message: error.message });
                }
                localStorage.setItem("message_alert", alert);
                this.$emit('triggerAlert', alert);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                this.formValidate();
            });
        },
        parseJwt: function (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        },
        loadPage() {
            const token = localStorage.getItem("token");
            const parsedToken = this.parseJwt(token);
            let company_param = { page: 1, pageSize: 10000 };
            if (parsedToken.comId) {
                company_param.search = parsedToken.comId;
            }
            axios.get(process.env.VUE_APP_API_HOST + '/company', {
                params: company_param,
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                if (response.data.data.length==1) {
                    this.company_id = response.data.data[0].code;
                }
                this.company_dropdown_data = response.data.data;
            })

            let query = this.$route.query;
            this.parent_id = query.parent_id ? query.parent_id:null;
            if (this.parent_id!=null) {
                this.disableChildrenStatus = true;
                axios.get(process.env.VUE_APP_API_HOST + '/employee', {
                    params: { parent_id: this.parent_id },
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem("token")
                    }
                }).then((response) => {
                    this.spouse = response.data.data.filter(member => member.employee_status == "P").length;
                    this.children = response.data.data.filter(member => member.employee_status == "K").length;
                    axios.get(process.env.VUE_APP_API_HOST + '/employee/' + this.parent_id, {
                        headers: {
                            'Authorization': `Bearer ` + localStorage.getItem("token")
                        }
                    }).then((r) => {
                        this.parent_product = r.data.data.products
                        this.parent_name = r.data.data.employee_name;
                        this.employee_name = r.data.data.employee_name;
                        this.company_id = r.data.data.company_code;
                        this.marital_status = r.data.data.marital_status;
                        this.member_status = this.spouse == 0 && r.data.data.marital_status=='M'?'P':'K';
                        this.employee_effective_date = r.data.data.effective_date;
                    });
                });
                

            }
        },
        itsChanged(field) {
            this[field + "_error"] = "";
        },
        formValidate() {
            if (this.company_id == null || this.company_id == "") {
                this.company_id_error = "Please fill this field correctly"
            }
            if (this.policy_number == null || this.policy_number == "") {
                this.policy_number_error = "Please fill this field correctly"
            }
            if (this.member_status == null || this.member_status == "") {
                this.member_status_error = "Please fill this field correctly"
            }
            if (this.employee_name == null || this.employee_name == "") {
                this.employee_name_error = "Please fill this field correctly"
            }
            if (this.insured_name == null || this.insured_name == "") {
                this.insured_name_error = "Please fill this field correctly"
            }
            if (this.dob == null || this.dob == "") {
                this.dob_error = "Please fill this field correctly"
            }
            if (this.gender == null || this.gender == "") {
                this.gender_error = "Please fill this field correctly"
            }
            if (this.marital_status == null || this.marital_status == "") {
                this.marital_status_error = "Please fill this field correctly"
            }
            if (this.effective_date == null || this.effective_date == "") {
                this.effective_date_error = "Please fill this field correctly"
            }
        },
        showModal(id = null, inieffdate) {
            this.$refs.modalProduct.loadPage(id);
            this.isModalVisible = true;
            if (inieffdate != null) {
                if (this.$moment(inieffdate) <= this.$moment()) {
                    this.disableeditmodal = true;
                } else {
                    this.disableeditmodal = false;
                }
            } else {
                this.disableeditmodal = false;
            }
        },
        closeModal() {
            this.isModalVisible = false;
        },
        onClickChild(products) {
            let exist = this.productSelected.findIndex(e => (e.product_id === products.product_id && (e.inieffdate == null || this.$moment(e.inieffdate)<this.$moment() )))
            if (exist > 0) {
                this.productSelected.splice(exist, 1);
            }
            this.productSelected = [...this.productSelected, products]
        },
        inactive() {
            if (this.inactive_status == "0") {
                this.inactiveStatus = false;
            } else {
                this.inactiveStatus = true;
            }
        },
        copyProduct() {
            this.productSelected = this.parent_product;
        },
        formatNumber(n) {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        formatCurrency(element) {
            let input_val = this[element];

            if (input_val === "" || input_val === null)
                input_val = " "
            // let original_len = input_val.length;

            // let caret_pos = input.prop("selectionStart");

            if (input_val.indexOf(",") >= 0) {

                let decimal_pos = input_val.indexOf(",");

                let left_side = input_val.substring(0, decimal_pos);
                let right_side = input_val.substring(decimal_pos);

                left_side = this.formatNumber(left_side);

                right_side = this.formatNumber(right_side);

                // if (blur === "blur") {
                //     right_side += "00";
                // }

                right_side = right_side.substring(0, 2);

                input_val = "Rp " + left_side + "," + right_side;

            } else {
                input_val = this.formatNumber(input_val);
                input_val = "Rp " + input_val;

                // if (blur === "blur") {
                //     input_val += ",00";
                // }
            }

            this[element] = input_val;

            // let updated_len = input_val.length;
            // caret_pos = updated_len - original_len + caret_pos;
            // input[0].setSelectionRange(caret_pos, caret_pos);

        },
        formatInt(element) {
            let input_val = this[element];

            if (input_val === "" || input_val === null)
                input_val = " "
            input_val = this.formatNumber(input_val).replace(/\./g, "");
            this[element] = input_val;
        }
    },
    mounted() {
        this.loadPage();
    },
    computed: {
        isInactive() {
            if (this.inactiveStatus) {
                return false
            } else {
                return true
            }
        },
    },
};
</script>