<template>
    <div class="flex flex-wrap mt-4">
        <div class="w-full mb-12 px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div class="rounded-t bg-white mb-0 px-6 py-6">
                    <div class="text-center flex justify-between">
                        <h6 class="text-blueGray-700 text-xl font-bold">New User</h6>

                    </div>
                </div>
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form @submit="save">
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            User Information
                        </h6>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="name">
                                        Name <span class="text-red-500">*</span>
                                    </label>
                                    <input type="text" name="name" v-model="name"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="username">
                                        Username <span class="text-red-500">*</span>
                                    </label>
                                    <input type="text" name="username" v-model="username"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email">
                                        Email <span class="text-red-500">*</span>
                                    </label>
                                    <input type="email" name="email" v-model="email"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                                
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="password">
                                        Password <span class="text-red-500">*</span>
                                    </label>
                                    <input type="password" name="password" v-model="password"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">

                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="role">
                                        Role <span class="text-red-500">*</span>
                                    </label>
                                    <select name="role" v-model="role" @change="isSuperadmin($event)"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option value="superadmin">superadmin</option>
                                        <option value="hrd">HRD</option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3" :hidden='isHrd'>
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="company_id">
                                        Company <span class="text-red-500">*</span>
                                    </label>
                                    <select name="company_id" v-model="company_id"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option v-for="(item, key) in api_data" :key="key" :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                <div class="flex flex-wrap">
                    <div class="w-full lg:w-12/12 px-4 mt-3 mb-6">
                        <button
                            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="submit">
                            Add
                        </button>
                    </div></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            api_data: null,
            username: null,
            password: null,
            company_id: null,
            name: null,
            email: null,
            role: 'hrd',
            companyDropdown: true,
        }
    },
    methods: {
        save(form) {
            form.preventDefault();
            let data = {
                username: this.username,
                password: this.password,
                company_id: this.company_id,
                name: this.name,
                email: this.email,
                role: this.role
            }
            if (this.role =='superadmin')
                data.company_id = null;
            axios.post(process.env.VUE_APP_API_HOST + '/login/create-account', data, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                if (response.data.status == 200 || response.data.status == 201) {
                    let alert = JSON.stringify({ title: "Success", message: response.data.message });
                    localStorage.setItem("message_alert", alert);
                    this.$router.push("/admin/user/detail/" + response.data.data.id)
                } else {
                    this.$Progress.fail();
                    let alert = JSON.stringify({ title: "Failed", message: response.data.message, errors: response.data.detail });
                    localStorage.setItem("message_alert", alert);
                    this.$emit('triggerAlert', alert);
                }
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }).catch(error =>  {
                let alert;
                if (error.response) {
                    alert = JSON.stringify({ title: "Failed", message: error.response.data.message, errors: error.response.data.detail });
                } else {
                    alert = JSON.stringify({ title: "Failed", message: error.message });
                }
                localStorage.setItem("message_alert", alert);
                this.$emit('triggerAlert', alert);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        },
        loadPage() {
            axios.get(process.env.VUE_APP_API_HOST + '/company', {
                params: {page:1,pageSize:10000},
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                this.api_data = response.data.data;
            })
        },
        isSuperadmin() {
            if (this.role != "hrd") {
                this.companyDropdown = false;
            } else {
                this.companyDropdown = true;
            }
        }

    },
    mounted(){
        this.loadPage();
    },
    computed: {
        isHrd() {
            if (this.companyDropdown) {
                return false
            } else {
                return true
            }
        },
    },
};
</script>