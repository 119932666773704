<template>
    <div class="flex flex-wrap mt-4">
        <div class="w-full mb-12 px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div class="rounded-t bg-white mb-0 px-6 py-6">
                    <div class="text-center flex justify-between">
                        <h6 class="text-blueGray-700 text-xl font-bold">{{editText}} Detail User</h6>

                    </div>
                </div>
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form @submit="update">
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            User Information
                        </h6>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="name">
                                        Name <span class="text-red-500">*</span>
                                    </label>
                                    <input type="text" name="name" v-model="name" :disabled='isDisabled'
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="username">
                                        Username <span class="text-red-500">*</span>
                                    </label>
                                    <input type="text" name="username" v-model="username" :disabled='isDisabled'
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="email">
                                        Email <span class="text-red-500">*</span>
                                    </label>
                                    <input type="email" name="email" v-model="email" :disabled='isDisabled'
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>

                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3" :class="(this.$route.params.id==user_id)?'hidden':''">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="password">
                                        Password
                                    </label>
                                    <input type="password" name="password" v-model="password" :disabled='isDisabled'
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                </div>
                                <div class="relative w-full mb-3" :class="(this.$route.params.id == user_id) ? '' :'hidden'">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="password">
                                        &nbsp;
                                    </label>
                                    <a href="/admin/user/change-password" ><button
                                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button">
                                        Change Password
                                    </button></a>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap" :class="(this.$route.params.id==user_id)?'hidden':''">
                        
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="role">
                                        Role <span class="text-red-500">*</span>
                                    </label>
                                    <select name="role" v-model="role" :disabled='isDisabled' @change="isSuperadmin($event)"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option value="superadmin" :selected="role">superadmin</option>
                                        <option value="hrd" :selected="role">HRD</option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3" :hidden='isHrd'>
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="company_id">
                                        Company <span class="text-red-500">*</span>
                                    </label>
                                    <select name="company_id" v-model="company_id" :disabled='isDisabled'
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option v-for="(item, key) in api_data" :key="key" :value="item.id"
                                            :selected="item.id==company_id?true:false">
                                            {{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-12/12 px-4 mt-3 mb-6">
                            <button @click="edit(this)"
                                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="submit">
                                {{ editButtonText }}
                            </button>
                            <button :hidden='isDisabled' @click="edit(this)"
                                class="bg-blueGray-600 text-white active:bg-grey-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            editButtonText: "Edit",
            editText: "",
            api_data: null,
            username: null,
            password: null,
            company_id: null,
            name: null,
            email: null,
            role: null,
            companyDropdown: true,
            user_id: null,
        }
    },
    methods: {
        loadPermission: function () {
            const token = localStorage.getItem("token");
            const parsedToken = this.parseJwt(token);
            this.user_id = parsedToken.sub;
        },
        parseJwt: function (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        },
        edit() {
            if (this.editButtonText == "Save") {
                this.editButtonText = "Edit";
                this.editText = "";
                this.loadPage();
            } else {
                this.editButtonText = "Save";
                this.editText = "Edit";
            }
        },
        update(form) {
            form.preventDefault();
            if (this.editButtonText == "Edit") {
                this.$Progress.start();
                let data = {
                    username: this.username,
                    name: this.name,
                    email: this.email,
                }
                if (this.password !== "") {
                    data.password = this.password;
                } else {
                    delete data.password;
                }
                if (this.role !== "") {
                    data.role = this.role;
                } else {
                    delete data.role;
                }
                if (this.company_id !== "") {
                    data.company_id = this.company_id;
                } else {
                    delete data.company_id;
                }
                let id = this.$route.params.id;
                if (this.role == 'superadmin')
                    delete data.company_id;
                axios.put(process.env.VUE_APP_API_HOST + '/login/' + id, data, {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem("token")
                    }
                }).then((response) => {
                    let alert = JSON.stringify({ title: "Success", message: response.data.message });
                    localStorage.setItem("message_alert", alert);
                    this.$router.go(0);
                }).catch(error =>  {
                    if (error.response) {
                        this.$Progress.fail();
                        let alert = JSON.stringify({ title: "Failed", message: error.response.data.message, errors: error.response.data.detail });
                        localStorage.setItem("message_alert",alert);
                        this.$emit('triggerAlert', alert);
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    } else {
                        this.$Progress.fail();
                        let alert = JSON.stringify({ title: "Failed", message: error.message });
                        localStorage.setItem("message_alert",alert);
                        this.$router.go(0);
                    }
                });
            }
        },
        loadPage() {
            this.loadPermission();
            axios.get(process.env.VUE_APP_API_HOST + '/company', {
                params: { page: 1, pageSize: 10000 },
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                this.api_data = response.data.data;
            })
            let id = this.$route.params.id;
            axios.get(process.env.VUE_APP_API_HOST + '/login/' + id, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                this.username = response.data.data.username;
                this.company_id = response.data.data.company_id;
                this.name = response.data.data.name;
                this.email = response.data.data.email;
                this.role = response.data.data.role;
                this.isSuperadmin();
            })
        },
        isSuperadmin() {
            if (this.role != "hrd") {
                this.companyDropdown = false;
            } else {
                this.companyDropdown = true;
            }
        }

    },
    computed: {
        isDisabled() {
            if (this.editButtonText == "Save") {
                return false
            } else {
                return true
            }
            // return yourCondition == true
        },
        isHrd() {
            if (this.companyDropdown) {
                return false
            } else {
                return true
            }
        },
    },
    mounted() {
        this.loadPage();
    }
};
</script>