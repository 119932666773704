<template>
    <div class="modal-backdrop">
        <div class="modal">
            <form @submit="addProduct">
            <header class="modal-header">
                <slot name="header">
                    <h6 class="text-blueGray-700 text-xl font-bold">Product Detail</h6>
                </slot>
                <button type="button" class="btn-close" @click="close">
                    x
                </button>
            </header>

            <section class="modal-body">
                <slot name="body">
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    Product <span class="text-red-500">*</span>
                                </label>
                                <select v-model="product_code" @blur="itsChanged('product_code')"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                    <option v-for="(item, key) in product_dropdown_data" :key="key" v-bind:value="{ id: item.id, code: item.product_code, text: item.product_name }">{{item.product_name}}</option>
                                </select>
                                <div class="text-red-500 text-right text-sm"><span>{{ product_code_error }}</span></div>
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                        </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    Currency <span class="text-red-500">*</span>
                                </label>
                                <select v-model="currency" @change="blurNumberInput,itsChanged('currency')"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                    <option value="$ ">$ (USD)</option>
                                    <option value="Rp ">RP (IDR)</option>
                                </select>
                                <div class="text-red-500 text-right text-sm"><span>{{ currency_error }}</span></div>
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    Uang Pertanggungan (UP) <span class="text-red-500">*</span>
                                </label>
                                <input type="text" ref="up" v-model="up" v-on:blur="formatCurrency('up')" v-on:keyup="formatCurrency('up')" @blur="itsChanged('up')"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                <div class="text-red-500 text-right text-sm"><span>{{ up_error }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                    Effective Date <span class="text-red-500">*</span>
                                </label>
                                <input type="date" v-model="effective_date" :min="$moment().subtract(1, 'months').format('YYYY-MM-DD')" @blur="itsChanged('effective_date')"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                <div class="text-red-500 text-right text-sm"><span>{{ effective_date_error }}</span></div>
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                    Termination Date
                                </label>
                                <input type="date" v-model="inieffdate"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                            </div>
                        </div>
                    </div>
                    
                    <div class="flex flex-wrap">
                    
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    Beneficiary Plan Code
                                </label>
                                <input type="text" v-model="bene_plan_code"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    cover code (Covgcode)
                                </label>
                                <input type="text" v-model="covgcode"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                    
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    Date of Participant (DoP)
                                </label>
                                <input type="date" v-model="dop"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    Date of Employee (DoE)
                                </label>
                                <input type="date" v-model="doe"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                            </div>
                        </div>
                    </div>
                    
                    <div class="flex flex-wrap">
                    
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    Proposed SA
                                </label>
                                <input type="text" ref="proposed_sa" v-model="proposed_sa" v-on:blur="formatCurrency('proposed_sa')" v-on:keyup="formatCurrency('proposed_sa')"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    SA
                                </label>
                                <input type="text" ref="sa" v-model="sa" v-on:blur="formatCurrency('sa')" v-on:keyup="formatCurrency('sa')"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    UW Status
                                </label>
                                <select v-model="uw_status"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                </select>
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                    Premium <span class="text-red-500">*</span>
                                </label>
                                <input type="text" ref="premium" v-model="premium" v-on:blur="formatCurrency('premium')" v-on:keyup="formatCurrency('premium')" @blur="itsChanged('premium')"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                <div class="text-red-500 text-right text-sm"><span>{{ premium_error }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                    Member NEL
                                </label>
                                <input type="text" ref="member_nel" v-model="member_nel" v-on:blur="formatCurrency('member_nel')" v-on:keyup="formatCurrency('member_nel')"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                    Note
                                </label>
                                <textarea type="text" v-model="note"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">-</textarea>
                            </div>
                        </div>
                    </div>

                </slot>
            </section>

            <footer class="modal-footer">
                <slot name="footer">
                </slot>
                <div class="w-full lg:w-12/12 px-4 mt-3 mb-6">
                    <button
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        :class="isdisable ? is_active?'':'hidden':''" type="submit">
                        Add
                    </button>
                    <button @click="close"
                        class="bg-blueGray-600 text-white active:bg-grey-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button">
                        {{ isdisable ? is_active ? 'Cancel' : "Close" : "Cancel" }}
                    </button>
                </div>
            </footer>
        </form>
        </div>
    </div>
</template>
<style>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    z-index:1000;
    overflow-x: auto;
}

.modal {
    background: rgba(241, 245, 249, 1);
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    max-width:70%;
    margin:0 auto 0 auto
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
}

.modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
}

.modal-body {
    position: relative;
    padding: 20px 10px;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
}

.btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
}
</style>
<script>
import axios from 'axios'

export default {
    props: ['isdisable'],
    data() {
        return {
            product_name: null,
            product_code: null,
            product_up: null,
            start_date: this.$moment().add(1, 'years').format('YYYY-MM-DD'),
            end_date: this.$moment().add(1, 'years').format('YYYY-MM-DD'),
            bene_plan_code: null,
            covgcode: null,
            dop: null,
            doe: null,
            proposed_sa: null,
            sa: null,
            member_nel: null,
            inieffdate: null,
            uw_status: null,
            effective_date: null,
            up: null,
            premium: null,
            is_active: false,
            note: null,
            id: null,
            product_dropdown_data: null,
            currency: "Rp ",
            product_code_error: "",
            currency_error: "",
            up_error: "",
            effective_date_error: "",
            premium_error: ""
        }
    },
    name: 'Modal',
    methods: {
        close() {
            this.$emit('close');
        },
        addProduct(form) {
            form.preventDefault();
            // let products = this.product_code.text;
            if (this.product_code != null && this.currency != null && this.formatInt(this.up) != "" && this.effective_date != null && this.formatInt(this.premium) != "") {
                let products = {
                    product_name: this.product_code.text,
                    product_code: this.product_code.code,
                    product_id: this.product_code.id,
                    // product_up: this.product_up,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    bene_plan_code: this.bene_plan_code,
                    covgcode: this.covgcode,
                    dop: this.dop,
                    doe: this.doe,
                    proposed_sa: this.formatInt(this.proposed_sa),
                    sa: this.formatInt(this.sa),
                    member_nel: this.formatInt(this.member_nel),
                    inieffdate: this.inieffdate,
                    uw_status: this.uw_status,
                    effective_date: this.effective_date,
                    up: this.formatInt(this.up),
                    premium: this.formatInt(this.premium),
                    note: this.note,
                    currency: this.currency=="Rp "?"idr":"usd",
                    is_active: this.inieffdate ? (this.$moment(this.inieffdate) <= this.$moment()?false:true):true,
                    id: this.id,
                    // this.$moment(
                }
                this.$emit('clicked', products);
                this.close();
            } else {
                if (this.product_code == null || this.product_code == "") {
                    this.product_code_error = "Please fill this field correctly"
                }

                if (this.currency == null || this.currency == "") {
                    this.product_code_error = "Please fill this field correctly"
                }

                if (this.effective_date == null || this.effective_date == "") {
                    this.effective_date_error = "Please fill this field correctly"
                }

                if (this.formatInt(this.up) == null || this.formatInt(this.up) == "") {
                    this.up_error = "Please fill this field correctly"
                }

                if (this.formatInt(this.premium) == null || this.formatInt(this.premium) == "") {
                    this.premium_error = "Please fill this field correctly"
                }
                
                // alert("Please complete the required fields");
                // this.close();
            }
            
        },
        loadPage(id=null) {
            axios.get(process.env.VUE_APP_API_HOST + '/product', {
                params: { page: 1, pageSize: 10000 },
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                this.product_dropdown_data = response.data.data;
            })
            if (id) {
                axios.get(process.env.VUE_APP_API_HOST + '/employee/employee_product/'+id, {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem("token")
                    }
                }).then((response) => {
                    this.product_code = { id: response.data.data.product_id, code: response.data.data.product_code, text: response.data.data.product_name };
                    this.product_up = response.data.data.product_up;
                    this.start_date = !response.data.data.start_date ? null : this.$moment(response.data.data.start_date).format('YYYY-MM-DD');
                    this.end_date = !response.data.data.end_date ? null : this.$moment(response.data.data.end_date).format('YYYY-MM-DD');
                    this.bene_plan_code = response.data.data.beneficiary_plan_code;
                    this.covgcode = response.data.data.covgcode;
                    this.dop = !response.data.data.dop ? null : this.$moment(response.data.data.dop).format('YYYY-MM-DD');
                    this.doe = !response.data.data.doe ? null : this.$moment(response.data.data.doe).format('YYYY-MM-DD');
                    this.proposed_sa = response.data.data.proposed_sa;
                    this.sa = response.data.data.sa;
                    this.member_nel = response.data.data.membernel;
                    this.inieffdate = !response.data.data.inieffdate ? null : this.$moment(response.data.data.inieffdate).format('YYYY-MM-DD');
                    this.uw_status = response.data.data.uwstatus;
                    this.effective_date = !response.data.data.product_effective_date ? null : this.$moment(response.data.data.product_effective_date).format('YYYY-MM-DD');
                    this.up = response.data.data.up;
                    this.premium = response.data.data.premium;
                    this.note = response.data.data.note;
                    this.is_active = response.data.data.is_active==0?false:true;
                    this.currency = response.data.data.currency=="idr"? "Rp ": "$ ";
                    this.id = id;
                })
            }
            setTimeout(() => {
                this.blurNumberInput();
            }, 500);
        },
        formatInt(n) {
            if (n==null) {
                return "";
            } else {
                n = n.replace(/Rp /g, "");
                n = n.replace(/\$ /g, "");
                n = n.replace(/\./g, "");
                return n;
            }
        },
        formatNumber(n) {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        formatCurrency(element) {
            let input_val = this[element];

            if (input_val === "" || input_val === null) 
                input_val = " " 
            // let original_len = input_val.length;

            // let caret_pos = input.prop("selectionStart");

            if (input_val.indexOf(",") >= 0) {

                let decimal_pos = input_val.indexOf(",");

                let left_side = input_val.substring(0, decimal_pos);
                let right_side = input_val.substring(decimal_pos);

                left_side = this.formatNumber(left_side);

                right_side = this.formatNumber(right_side);

                // if (blur === "blur") {
                //     right_side += "00";
                // }

                right_side = right_side.substring(0, 2);

                input_val = this.currency + left_side + "," + right_side;

            } else {
                input_val = this.formatNumber(input_val);
                input_val = this.currency + input_val;

                // if (blur === "blur") {
                //     input_val += ",00";
                // }
            }

            this[element] = input_val;

            // let updated_len = input_val.length;
            // caret_pos = updated_len - original_len + caret_pos;
            // input[0].setSelectionRange(caret_pos, caret_pos);

        },
        itsChanged(field) {
            this[field+"_error"] = "";
        },
        blurNumberInput() {
            this.$refs.up.addEventListener('click', this.formatCurrency('up'))
            this.$refs.proposed_sa.addEventListener('click', this.formatCurrency('proposed_sa'))
            this.$refs.sa.addEventListener('click', this.formatCurrency('sa'))
            this.$refs.member_nel.addEventListener('click', this.formatCurrency('member_nel'))
            this.$refs.premium.addEventListener('click', this.formatCurrency('premium'))
        }


    },
    mounted() {
        this.loadPage()
    }
};
</script>