<template>
    <div v-if="alertOpen" class="w-6/12 text-white ml-3 px-6 py-4 border-0 rounded relative mb-4" :class="title=='Success'?'bg-lightBlue-400':'bg-red-500'">
        <span class="text-xl inline-block mr-4 align-middle">
            <i class="fas fa-bell"></i>
        </span>
        <span class="inline-block align-middle mr-4">
            <b class="capitalize"> {{title}}</b> {{message}}
        </span>
            <ul class="bg-orange-200 px-4 py-4" :hidden="errors.length>0?false:true">
                <li class="text-blueGray-600" v-for="error in errors" :key="error">- {{ error.message }} {{ error.data ? "(" + error.data + ")" :""}}</li>
            </ul>
        <button
            class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mr-1 outline-none focus:outline-none"
            v-on:click="closeAlert()">
            <span>×</span>
        </button>
    </div>
</template>
<script>
export default {
    name: "emerald-alert",
    data() {
        return {
            alertOpen: false,
            title: "Success",
            message:"This is sample message",
            errors:[]
        }
    },
    mounted() {
        this.loadAlert();
    },
    methods: {
        closeAlert: function () {
            this.alertOpen = false;
        },
        loadAlert(msg) {
            let alert = msg ?msg:localStorage.getItem("message_alert");
            if (alert) {
                alert = JSON.parse(alert);
                this.alertOpen = true;
                this.title = alert.title;
                this.message = alert.message;
                this.errors = alert.errors ? alert.errors:[];
                setTimeout(() => {
                    localStorage.removeItem("message_alert");
                    this.alertOpen = false;
                }, 10000);
            }
        }
    }, 
    watch: {
        $route() {
            this.loadAlert()
        }
    }
}
</script>