<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full px-4">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">{{editText}} Product Detail</h6>

            </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit="update">
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                    Product Information
                </h6>
                <div class="flex flex-wrap">
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                Product Code <span class="text-red-500">*</span>
                            </label>
                            <input type="text" :disabled='isDisabled' v-model="product_code"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                             />
                        </div>
                    </div>
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                Product Name <span class="text-red-500">*</span>
                            </label>
                            <input type="text" :disabled='isDisabled' v-model="product_name"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                             />
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                Description <span class="text-red-500">*</span>
                            </label>
                            <textarea type="text" :disabled='isDisabled' v-model="product_description"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"></textarea>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4 mt-3 mb-6">
                    <button @click="edit(this)"
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit">
                        {{ editButtonText }}
                    </button>
                    <button :hidden='isDisabled' @click="edit(this)"
                        class="bg-blueGray-600 text-white active:bg-grey-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
  </div>
</template>

<script>

import axios from 'axios'
export default {
    data() {
        return {
            editButtonText: "Edit",
            editText: "",
            product_code: null,
            product_name: null,
            product_description: null,
        }
    },
    methods: {
        edit() {
            if (this.editButtonText == "Save") {
                this.editButtonText = "Edit";
                this.editText = "";
                this.loadPage();
            } else {
                this.editButtonText = "Save";
                this.editText = "Edit";
            }
        },
        update(form) {
            form.preventDefault();
            if (this.editButtonText == "Edit") {
                this.$Progress.start();
                let data = {
                    product_code: this.product_code,
                    product_name: this.product_name,
                    product_description: this.product_description
                }
                let id = this.$route.params.id;
                axios.put(process.env.VUE_APP_API_HOST + '/product/' + id, data, {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem("token")
                    }
                }).then((response) => {
                    if (response.data.status == 200 || response.data.status == 201) {
                        let alert = JSON.stringify({ title: "Success", message: response.data.message });
                        localStorage.setItem("message_alert", alert);
                        this.$router.go(0);
                    } else {
                        this.$Progress.fail();
                        let alert = JSON.stringify({ title: "Failed", message: response.data.message, errors: response.data.detail });
                        localStorage.setItem("message_alert", alert);
                        this.$emit('triggerAlert', alert);
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                }).catch(error => {
                    this.$Progress.fail();
                    let alert;
                    if (error.response) {
                        alert = JSON.stringify({ title: "Failed", message: error.response.data.message, errors: error.response.data.detail });
                    } else {
                        alert = JSON.stringify({ title: "Failed", message: error.message });
                    }
                    localStorage.setItem("message_alert", alert);
                    this.$emit('triggerAlert', alert);
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                });
            }
        },
        loadPage() {
            let id = this.$route.params.id;
            axios.get(process.env.VUE_APP_API_HOST + '/product/' + id, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                this.product_code = response.data.data.product_code;
                this.product_name = response.data.data.product_name;
                this.product_description = response.data.data.product_description;
            })
        }
    },
    computed: {
        isDisabled() {
            if (this.editButtonText == "Save") {
                return false
            } else {
                return true
            }
            // return yourCondition == true
        },
    },
    mounted() {
        this.loadPage()
    }
};
</script>