<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Family Member List
          </h3>
        </div>
        <div :hidden="maritalStatus == 'S' ? true : spouse==1&&children==2?true:false"
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <a :href="'/admin/employee/insert?parent_id='+(familyID?familyID:this.$route.params.id)">
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            Add New
          </button></a>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left light" >
              Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left light" 
            >
              Policy Number
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left light" 
            >
              Insured Name
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left light" 
            >
              Phone Number
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left light" 
            >
              Product
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left light" 
            ></th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(item, key) in api_data"  :key="key">
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {{item.employee_status=="E"?"Employee":item.employee_status=="P"?"Spouse":"Children"}}
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {{item.policy_number}}
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {{item.insured_name}}
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {{item.phone}}
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <p v-for="(product_item, product_key) in item.products"  :key="product_key">
              <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full mb-1 uppercase last:mr-0 mr-1"
              :class="product_item.is_active ? 'text-white bg-lime-700' :'text-white bg-red-600'">
                {{product_item.product_name}}
              </span>
            </p>
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
            <employee-table-dropdown :employeeid="item.id" />
          </td>
        </tr><tr>
          <td colspan="4">
            <div class="py-2 p-4 pb-6">
              <nav class="block">
                <ul class="flex pl-0 rounded list-none flex-wrap">
                  <li class="ml-1">
                    <span class="text-xs font-semibold inline-block py-1 px-2 last:mr-0 mr-1">Showing {{page_attr.from}} to {{page_attr.to}} data of {{page_attr.total_data}} results.</span>
                  </li>
                </ul>
              </nav>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import EmployeeTableDropdown from "@/components/Dropdowns/EmployeeTableDropdown.vue";

import axios from 'axios'

export default {
  data() {
    return {
      api_data: null,
      page_attr: { from: null, to: null, total_page: null, total_data: null, page: null, page_size: null},
      search:null,
      product:null,
      product_dropdown_data: null,
      spouse: null,
      children: null,
    };
  },
  components: {
    EmployeeTableDropdown,
  },
  props: ['familyID', 'maritalStatus'],
  methods:{
    loadPage(){
        axios.get(process.env.VUE_APP_API_HOST+'/employee', {
          params: { parent_id: this.familyID },
        headers: {
          'Authorization': `Bearer `+localStorage.getItem("token")
        }
      }).then((response) => {
        this.spouse = response.data.data.filter(member => member.employee_status == "P").length;
        this.children = response.data.data.filter(member => member.employee_status == "K").length;
        this.api_data = response.data.data;
        this.page_attr.total_data = response.data.total_data;
        this.page_attr.page = response.data.page;
        this.page_attr.page_size = response.data.page_size;
        this.page_attr.from = response.data.from;
        this.page_attr.to = response.data.to;
        this.page_attr.total_page = response.data.total_page;
      })

      axios.get(process.env.VUE_APP_API_HOST + '/product', {
        params: { page: 1, pageSize: 10000 },
        headers: {
          'Authorization': `Bearer ` + localStorage.getItem("token")
        }
      }).then((response) => {
        this.product_dropdown_data = response.data.data;
      })

    },
  },
  mounted() {
    this.loadPage()
  },
  watch: {
    $route(r) {
      if (r.path.includes("/admin/employee")) {
        this.loadPage();
      }
    }
  }
};
</script>
