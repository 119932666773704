<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
     <div class="relative bg-emerald-600 pb-32 pt-12">
      <div class="px-4 md:px-10 mx-auto w-full">
      </div>
    </div>
    <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <global-messages ref="alert_message" />
        <router-view @trigger-alert="triggerAlert"/>
        <footer-admin />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
import GlobalMessages from "@/components/Alert/GlobalMessages.vue";
export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    Sidebar,
    FooterAdmin,
    GlobalMessages,
  }, 
  methods: {
    triggerAlert(msg) {
      this.$refs.alert_message.loadAlert(msg);
    }
  }
};
</script>
