<template>
    <div>
        <div class="flex flex-wrap mt-4">
            <div class="w-full px-4">
                <div
                    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                    <div class="rounded-t bg-white mb-0 px-6 py-6">
                        <div class="text-center flex justify-between">
                            <h6 class="text-blueGray-700 text-xl font-bold">{{editText}} Employee Detail</h6>
    
                        </div>
                    </div>
                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                        <form @submit="update">
                            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Employee Information
                            </h6>
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Company Name <span class="text-red-500">*</span>
                                        </label>
                                        <select name="company_id" v-model="company_id" :disabled="editButtonText == 'Edit'?true:disableChildrenStatus" @blur="itsChanged('company_id')"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option v-for="(item, key) in company_dropdown_data" :key="key"
                                                :value="item.code">{{item.name}}
                                            </option>
                                        </select>
                                        <div class="text-red-500 text-right text-sm"><span>{{ company_id_error }}</span></div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Policy Number <span class="text-red-500">*</span>
                                        </label>
                                        <input type="text" v-model="policy_number" name="policy_number" @blur="itsChanged('policy_number')"
                                            :disabled="isDisabled"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                        <div class="text-red-500 text-right text-sm"><span>{{ policy_number_error }}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Member Status <span class="text-red-500">*</span>
                                        </label>
                                        <select v-model="member_status" :disabled="isDisabled" @blur="itsChanged('member_status')"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="E" :disabled="parent_id?true:false">Employee</option>
                                            <option value="P" :disabled="parent_id ? parent_marital_status == 'W' ? true : spouse==1?true:false:true">Spouse</option>
                                            <option value="K" :disabled="parent_id ?children<2?false:true:true">Children</option>
                                        </select>
                                        <div class="text-red-500 text-right text-sm"><span>{{ member_status_error }}</span></div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Participant No
                                        </label>
                                        <input v-model="member_id" :disabled="isDisabled"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Dependant Code (DepCode)
                                        </label>
                                        <input v-model="dep_code" :disabled="isDisabled"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Dependant Type (DepType)
                                        </label>
                                        <input v-model="dep_type" :disabled="isDisabled"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
    
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Employee Name <span class="text-red-500">*</span>
                                        </label>
                                        <input type="text" v-model="employee_name" :disabled="editButtonText == 'Edit'?true:disableChildrenStatus" @blur="itsChanged('employee_name')"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                        <div class="text-red-500 text-right text-sm"><span>{{ employee_name_error }}</span></div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            NIK
                                        </label>
                                        <input type="text" v-model="nik" :disabled="isDisabled" v-on:blur="formatInt('nik')" v-on:keyup="formatInt('nik')" 
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
    
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Employee Number
                                        </label>
                                        <input type="text" v-model="employee_number" :disabled="isDisabled"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Certificate Number
                                        </label>
                                        <input type="text" v-model="certificate_number" name="certificate_number"
                                            :disabled="isDisabled"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
    
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Insured Name <span class="text-red-500">*</span>
                                        </label>
                                        <input type="text" v-model="insured_name" name="insured_name" :disabled="isDisabled" @blur="itsChanged('insured_name')"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                        <div class="text-red-500 text-right text-sm"><span>{{ insured_name_error }}</span></div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Date of Birth <span class="text-red-500">*</span>
                                        </label>
                                        <input type="date" v-model="dob" :disabled="isDisabled" :max="$moment(Date.now()).format('YYYY-MM-DD')" @blur="itsChanged('dob')"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                        <div class="text-red-500 text-right text-sm"><span>{{ dob_error }}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
    
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Gender <span class="text-red-500">*</span>
                                        </label>
                                        <select v-model="gender" :disabled="isDisabled" @blur="itsChanged('gender')"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                        </select>
                                        <div class="text-red-500 text-right text-sm"><span>{{ gender_error }}</span></div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Marital Status <span class="text-red-500">*</span>
                                        </label>
                                        <select v-model="marital_status" :disabled="isDisabled" @blur="itsChanged('marital_status')"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value="S" :disabled="member_status=='P'?true:false">Single</option>
                                            <option value="M">Married</option>
                                            <option value="D" :disabled="member_status=='P'?true:false">Divorced</option>
                                        </select>
                                        <div class="text-red-500 text-right text-sm"><span>{{ marital_status_error }}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Beneficiary Name
                                        </label>
                                        <input type="text" v-model="beneficiary_name" :disabled="isDisabled"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Phone Number
                                        </label>
                                        <input type="text" v-model="phone_number" :disabled="isDisabled" v-on:blur="formatInt('phone_number')" v-on:keyup="formatInt('phone_number')" 
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
    
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Bank Name
                                        </label>
                                        <input type="text" v-model="bank_name" :disabled="isDisabled"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Bank Account Number
                                        </label>
                                        <input type="text" v-model="bank_account" :disabled="isDisabled" v-on:blur="formatInt('bank_account')" v-on:keyup="formatInt('bank_account')" 
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Salary
                                        </label>
                                        <input type="text" v-model="salary" :disabled="isDisabled" v-on:blur="formatCurrency('salary')" v-on:keyup="formatCurrency('salary')"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                    </div>
                                </div>
                                <!-- <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            >
                                            Movement History
                                        </label>
                                        <textarea type="text" v-model="movement_history" :disabled="isDisabled"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                                                                                    -
                                                                                                </textarea>
                                    </div>
                                </div> -->
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                            Effective Date<span class="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="date"
                                            :min="minDate"
                                            v-model="effective_date"
                                            :disabled="isDisabled"
                                            @blur="itsChanged('effective_date')"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        />
                                        <div class="text-red-500 text-right text-sm"><span>{{ effective_date_error }}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
    
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                            Employee Status
                                        </label>
                                        <select :disabled='isDisabled' @change="inactive($event)" v-model="inactive_status"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            value="">
                                            <option value=0>Active</option>
                                            <option value=1>Terminate</option>
                                                                                <option value=2>Death</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                        <div class="w-full lg:w-6/12 px-4">
                                                                            <div class="relative w-full mb-3" :hidden='isTransferDate'>
                                                                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                                                                        Transfer Date
                                                                                </label>
                                                                                <input type="date" v-model="transfer_date" :min="minDate" :max="$moment(Date.now()).add(1, 'weeks').format('YYYY-MM-DD')"
                                                                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                        <div class="flex flex-wrap">
                                                                            <div class="w-full lg:w-6/12 px-4">
                                                                                <div class="relative w-full mb-3">
                                                                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                                                                        Note
                                                                                    </label>
                                                                                    <textarea type="text" v-model="note" :disabled='isDisabled'
                                                                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                                                                                                                        -
                                                                                                                                    </textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="w-full lg:w-6/12 px-4">
                                                                                <div class="relative w-full mb-3" :hidden='isInactive'>
                                                                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                                                                            Termination Date
                                                                                    </label>
                                                                                    <input type="date" :min="minDate" :disabled='isDisabled' v-model="inactive_date" :max="$moment(Date.now()).add(1, 'weeks').format('YYYY-MM-DD')"
                                                                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                                                                </div>
                                                                                <div class="relative w-full mb-3" :hidden='isDeath'>
                                                                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                                                                            Date of Death
                                                                                    </label>
                                                                                    <input type="date" :disabled='isDisabled' v-model="insured_dod" :max="$moment(Date.now()).add(1, 'weeks').format('YYYY-MM-DD')"
                                                                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                    <div class="flex flex-wrap">
                                                                        <div class="w-full lg:w-12/12 px-4">
                                                                            <div class="relative w-full mb-3">
                                                                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                                                                    Product
                                                                                </label>
                                                                                <span v-for="(item, key) in productSelected" :key="key" @click="showModal(item.id, item.inieffdate)"
                                                                                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full mb-1 uppercase last:mr-0 mr-1"
                                                                                    :class="item.is_active ? 'text-white bg-lime-700' : 'text-white bg-red-600'">
                                                                                    <a href="javascript:void(0)">{{ item.product_name }}</a>
                                                                                </span>
                                        
    
                                                                            </div>
                                                                            <div class="w-full lg:w-12/12 mt-3 mb-6">
                                                                                <button @click="showModal(null, null)" :hidden="editButtonText == 'Save' ? false : true"
                                                                                    class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                    type="button">
                                                                                    Add Product
                                                                                </button>
                                                                                <EmployeeModal v-show="isModalVisible" @close="closeModal" ref="modalProduct" :isdisable="disableeditmodal"
                                                                                    @clicked="onClickChild" />
                                                                            </div>
                                                                        </div>
    
                                                                    </div>
                                                                    <div class="w-full lg:w-12/12 px-4 mt-3 mb-6">
                                                                        <button @click="edit($event)"
                                                                            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                            type="submit">
                                                                            {{ editButtonText }}
                                                                        </button>
                                                                        <button :hidden='isDisabled' @click="edit($event)"
                                                                            class="bg-blueGray-600 text-white active:bg-grey-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                            type="button">
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="w-full px-4">
                                                        <div
                                                            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                                                            <div class="rounded-t bg-white mb-0 px-6 py-6">
                                                                <div class="text-center flex justify-between">
                                                                    <h6 class="text-blueGray-700 text-xl font-bold">Employee Mutation History</h6>
    
                                                                </div>
                                                            </div>

                                                            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                                                                <div class="block w-full overflow-x-auto mt-3" style="max-height: 530px;">
                                                                <!-- Employee Mutation History table -->
                       
                                                                <table class="items-center w-full bg-transparent border-collapse">
                                                                    <thead>
                                                                    <tr>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                
                                                                        >
                                                                        Created by
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Employee Name
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        From Company
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        To Company
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Product
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Old NIK
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        New NIK
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Old UP
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        New UP
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        DOB
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Gender
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Effective Date
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Resign Date
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Death Date
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Employee Duration (Days)
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Status Employee
                                                                        </th>
                                                                        <th
                                                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-white bg-emerald-800"
                                                                        >
                                                                        Keterangan
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr v-for="(item, key) in api_history_data"  :key="key">
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                         {{ item?.user?.name || "-" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                         {{ item.employees.employee_name }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item.company_from?.name || "-" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item?.company_to?.name || "-" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                            <span v-for="(data, key) in productSelected" :key="key" >
                                                                                    <a v-if="data.product_id === item.product_id && data.is_active">{{ data.product_name }}</a>
                                                                            </span>
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item?.old_nik || "-" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item?.new_nik || "-" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item?.old_up || "-" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item?.new_up || "-" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ dob || "-" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ gender || "-" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item.effective_date ? this.$moment(item.effective_date).format('YYYY-MM-DD') : '-' }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item.resign_date ? this.$moment(item.resign_date).format('YYYY-MM-DD') : '-' }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item?.death_date ? this.$moment(item?.death_date).format('YYYY-MM-DD') : '-' }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item?.employment_duration || "-" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ status_employee === 0 ? "Active" : status_employee === 1 ? "Inactive" : "Death" }}
                                                                        </td>
                                                                        <td
                                                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                                                        >
                                                                        {{ item?.status || "-" }}
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w-full mb-12 px-4" :hidden="(member_status == 'E' && marital_status == 'S') ? true : false">
                                                    <employee-family-table :familyID="family_id" :maritalStatus="parent_marital_status" v-if="family_id" />
            
                                                </div>
                                            </div>
</template>

<script>
import EmployeeFamilyTable from "@/components/EmployeeFamilyTable.vue";
import EmployeeModal from "@/components/Modal/EmployeeAddProduct.vue";
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        const token = localStorage.getItem("token");
        const parsedToken = this.parseJwt(token);
        return {
            company_dropdown_data: null,
            editButtonText: "Edit",
            user: parsedToken.role,
            editText: "",
            isModalVisible: false,
            inactiveStatus: false,
            company_id: null,
            company_default: null,
            policy_number: null,
            certificate_number: null,
            nik: null,
            parent_id: null,
            employee_name: null,
            member_status: null,
            insured_name: null,
            dob: null,
            gender: null,
            marital_status: null,
            movement_history: null,
            inactive_status: null,
            status_employee: null,
            inactive_date: null,
            insured_dod: null,
            transfer_date: null,
            phone_number: '',
            bank_name: null,
            bank_account: null,
            beneficiary_name: null,
            salary: null,
            employee_number: null,
            member_id: null,
            dep_code: null,
            dep_type: null,
            effective_date: null,
            note: null,
            productSelected: [],
            employeeData: null,
            spouse: null,
            children: null,
            family_id: null,
            disableChildrenStatus: true,
            employee_effective_date: null,
            parent_marital_status: null,
            disableeditmodal: false,
            company_id_error: '',
            policy_number_error: '',
            member_status_error: '',
            employee_name_error: '',
            insured_name_error: '',
            dob_error: '',
            gender_error: '',
            marital_status_error: '',
            effective_date_error: '',
            api_history_data: null,
            page_history_attr: { from: null, to: null, total_page: null, total_data: null, page: null, page_size: null },
        }
    },
    components: {
        EmployeeModal,
        EmployeeFamilyTable,
    },
    methods: {
        edit(dom) {
            if (this.editButtonText == "Save") {
                this.editButtonText = "Edit";
                this.editText = "";
                if (dom.target.outerText == "CANCEL") {
                    this.loadPage();
                }
            } else {
                this.editButtonText = "Save";
                this.editText = "Edit";
            }
        },
        itsChanged(field) {
            this[field + "_error"] = "";
        },
        update(form) {
            form.preventDefault();
            if (this.editButtonText == "Edit") {
                this.$Progress.start();
                let employeeData = {
                    company_code: this.company_id,
                    policy_number: this.policy_number,
                    certificate_number: this.certificate_number,
                    nik: this.nik,
                    parent_id: this.parent_id,
                    employee_name: this.employee_name,
                    employee_status: this.member_status,
                    insured_name: this.insured_name,
                    insured_dob: this.dob,
                    insured_gender: this.gender,
                    marital_status: this.marital_status,
                    history_movement: this.movement_history,
                    inactive_status: parseInt(this.inactive_status),
                    inactive_date: this.inactive_date,
                    insured_dod: this.insured_dod,
                    transfer_date: this.transfer_date,
                    phone: this.phone_number,
                    bank_name: this.bank_name,
                    bank_account_number: this.bank_account,
                    bank_beneficiary: this.beneficiary_name,
                    salary: this.salary,
                    employee_number: this.employee_number,
                    member_id: this.member_id,
                    dep_code: this.dep_code,
                    dep_type: this.dep_type,
                    effective_date: this.effective_date,
                    note: this.note,
                    products: this.productSelected,
                }
                let id = this.$route.params.id;
                axios.put(process.env.VUE_APP_API_HOST + '/employee/' + id, employeeData, {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem("token")
                    }
                }).then((response) => {
                    if (response.data.status == 200 || response.data.status == 201) {
                        let alert = JSON.stringify({ title: "Success", message: response.data.message });
                        localStorage.setItem("message_alert", alert);
                        this.$router.go(0);
                    } else {
                        this.$Progress.fail();
                        let alert = JSON.stringify({ title: "Failed", message: response.data.message, errors: response.data.detail });
                        localStorage.setItem("message_alert", alert);
                        this.$emit('triggerAlert', alert);
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                        this.formValidate();
                    }
                }).catch(error => {
                    this.$Progress.fail();
                    let alert;
                    if (error.response) {
                        alert = JSON.stringify({ title: "Failed", message: error.response.data.message, errors: error.response.data.detail });
                    } else {
                        alert = JSON.stringify({ title: "Failed", message: error.message });
                    }
                    localStorage.setItem("message_alert", alert);
                    this.$emit('trigger-alert', alert);
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                    this.formValidate();
                });
            }
        },
        formValidate() {
            if (this.company_id == null || this.company_id == "") {
                this.company_id_error = "Please fill this field correctly"
            }
            if (this.policy_number == null || this.policy_number == "") {
                this.policy_number_error = "Please fill this field correctly"
            }
            if (this.member_status == null || this.member_status == "") {
                this.member_status_error = "Please fill this field correctly"
            }
            if (this.employee_name == null || this.employee_name == "") {
                this.employee_name_error = "Please fill this field correctly"
            }
            if (this.insured_name == null || this.insured_name == "") {
                this.insured_name_error = "Please fill this field correctly"
            }
            if (this.dob == null || this.dob == "") {
                this.dob_error = "Please fill this field correctly"
            }
            if (this.gender == null || this.gender == "") {
                this.gender_error = "Please fill this field correctly"
            }
            if (this.marital_status == null || this.marital_status == "") {
                this.marital_status_error = "Please fill this field correctly"
            }
            if (this.effective_date == null || this.effective_date == "") {
                this.effective_date_error = "Please fill this field correctly"
            }
        },
        showModal(id, inieffdate) {
            this.$refs.modalProduct.loadPage(id);
            this.isModalVisible = true;
            if (inieffdate != null) {
                if (this.$moment(inieffdate) <= this.$moment()) {
                    this.disableeditmodal = true;
                } else {
                    this.disableeditmodal = false;
                }
            } else {
                this.disableeditmodal = false;
            }
        },
        closeModal() {
            this.isModalVisible = false;
        },
        onClickChild(productselected) {
            let exist = this.productSelected.findIndex(e => (e.product_id === productselected.product_id && (e.inieffdate == null || this.$moment(e.inieffdate) > this.$moment())))
            if (exist >= 0) {
                this.productSelected.splice(exist, 1);
            }
            this.productSelected = [...this.productSelected, productselected]
        },
        inactive() {
            if (this.inactive_status == 0) {
                this.inactiveStatus = false;
            } else {
                this.inactiveStatus = true;
            }
        },
        parseJwt: function (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        },
        loadPage() {
            const token = localStorage.getItem("token");
            const parsedToken = this.parseJwt(token);
            let company_param = { page: 1, pageSize: 10000 };
            if (parsedToken.comId) {
                company_param.search = parsedToken.comId;
            }
            axios.get(process.env.VUE_APP_API_HOST + '/company', {
                params: company_param,
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                if (response.data.data.length == 1) {
                    this.company_id = response.data.data[0].code;
                }
                this.company_dropdown_data = response.data.data;
            })
            let id = this.$route.params.id;
            axios.get(process.env.VUE_APP_API_HOST + '/employee/' + id, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                }
            }).then((response) => {
                this.company_id = response.data.data.company_code;
                this.company_default = response.data.data.company_code;
                this.policy_number = response.data.data.policy_number;
                this.certificate_number = response.data.data.certificate_number;
                this.nik = response.data.data.nik;
                this.parent_id = response.data.data.parent_id;
                this.employee_name = response.data.data.employee_name;
                this.member_status = response.data.data.employee_status;
                this.insured_name = response.data.data.insured_name;
                this.dob = !response.data.data.insured_dob ? null : this.$moment(response.data.data.insured_dob).format('YYYY-MM-DD');
                this.gender = response.data.data.insured_gender;
                this.marital_status = response.data.data.marital_status;
                this.movement_history = response.data.data.history_movement;
                this.inactive_status = response.data.data.inactive_status;
                this.status_employee = this.inactive_status;
                this.inactiveStatus = this.inactive_status == 0 ? false : true;
                this.inactive_date = !response.data.data.inactive_date ? null : this.$moment(response.data.data.inactive_date).format('YYYY-MM-DD');
                this.insured_dod = !response.data.data.insured_dod ? null : this.$moment(response.data.data.insured_dod).format('YYYY-MM-DD');
                this.transfer_date= !response.data.data.transfer_date ? null : this.$moment(response.data.data.transfer_date).format('YYYY-MM-DD');
                this.phone_number = response.data.data.phone ? response.data.data.phone : '';
                this.bank_name = response.data.data.bank_name;
                this.bank_account = response.data.data.bank_account_number;
                this.beneficiary_name = response.data.data.bank_beneficiary;
                this.salary = response.data.data.salary;
                this.employee_number = response.data.data.employee_number;
                this.member_id = response.data.data.member_id;
                this.dep_code = response.data.data.dep_code;
                this.dep_type = response.data.data.dep_type;
                this.effective_date = !response.data.data.effective_date ? null : this.$moment(response.data.data.effective_date).format('YYYY-MM-DD');
                this.note = response.data.data.note;
                this.productSelected = response.data.data.products;

                this.family_id = this.parent_id ? this.parent_id : id;
                if (this.parent_id !== null) {
                    this.disableChildrenStatus = true;
                    axios.get(process.env.VUE_APP_API_HOST + '/employee', {
                        params: { parent_id: this.parent_id },
                        headers: {
                            'Authorization': `Bearer ` + localStorage.getItem("token")
                        }
                    }).then((response) => {
                        this.spouse = response.data.data.filter(member => member.employee_status == "P").length - 1;
                        this.children = response.data.data.filter(member => member.employee_status == "K").length - 1;
                        axios.get(process.env.VUE_APP_API_HOST + '/employee/' + this.parent_id, {
                            headers: {
                                'Authorization': `Bearer ` + localStorage.getItem("token")
                            }
                        }).then((r) => {
                            this.parent_marital_status = r.data.data.marital_status;
                            this.employee_effective_date = r.data.data.effective_date;
                        });
                    });

                } else {
                    this.disableChildrenStatus = false;
                    this.parent_marital_status = this.marital_status
                }
            })
        },
        formatNumber(n) {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        formatCurrency(element) {
            let input_val = this[element];

            if (input_val === "" || input_val === null)
                input_val = " "
            // let original_len = input_val.length;

            // let caret_pos = input.prop("selectionStart");

            if (input_val.indexOf(",") >= 0) {

                let decimal_pos = input_val.indexOf(",");

                let left_side = input_val.substring(0, decimal_pos);
                let right_side = input_val.substring(decimal_pos);

                left_side = this.formatNumber(left_side);

                right_side = this.formatNumber(right_side);

                // if (blur === "blur") {
                //     right_side += "00";
                // }

                right_side = right_side.substring(0, 2);

                input_val = "Rp " + left_side + "," + right_side;

            } else {
                input_val = this.formatNumber(input_val);
                input_val = "Rp " + input_val;

                // if (blur === "blur") {
                //     input_val += ",00";
                // }
            }

            this[element] = input_val;

            // let updated_len = input_val.length;
            // caret_pos = updated_len - original_len + caret_pos;
            // input[0].setSelectionRange(caret_pos, caret_pos);

        },
        formatInt(element) {
            let input_val = this[element];

            if (input_val === "" || input_val === null)
                input_val = " "
            input_val = this.formatNumber(input_val).replace(/\./g, "");
            this[element] = input_val;
        },
        loadPageHistory(q = null) {
            let query;
            if (q == null) {
                query = this.$route.query;
            } else {
                query = q;
            }
            if (this.company_id != null) {
                query.company_id = this.company_id;
            }
            let id = this.$route.params.id;
            axios.get(process.env.VUE_APP_API_HOST + '/employee/mutation-history-by-employee-id/' + id, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem("token")
                },
                params: { pageSize: 10000 },
            }).then((response) => {
                this.api_history_data = response.data.data;
                this.page_history_attr.total_data = response.data.total_data;
                this.page_history_attr.page = response.data.page;
                this.page_history_attr.page_size = response.data.page_size;
                this.page_history_attr.from = response.data.from;
                this.page_history_attr.to = response.data.to;
                this.page_history_attr.total_page = response.data.total_page;
            });
        },
        calculateMinDate() {
            return moment().subtract(1, 'month').format('YYYY-MM-DD');
        },
    },
    computed: {
        isDisabled() {
            if (this.editButtonText == "Save") {
                return false
            } else {
                return true
            }
        },
        isInactive() {
            if (this.inactiveStatus && this.inactive_status != 2) {
                return false
            } else {
                return true
            }
        },
        isDeath() {
            if (this.inactive_status == 2) {
                return false
            } else {
                return true
            }
        },
        isTransferDate(){
            if(this.company_default === this.company_id){
                return true
            } else {
                return false
            }
        },
        minDate() {
            if (this.user === 'hrd') {
                return this.calculateMinDate();
            } else {
                return null;
            }
        },
    },
    watch: {
        $route(r) {
            if (r.path.includes("/admin/employee/")) {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                this.loadPage()
            }
        }
    },
    mounted() {
        this.loadPage();
        this.loadPageHistory();
    },
};
</script>