<template>
    <div class="rounded p-2">
        <div v-on:click="isOpen = !isOpen"
            class="font-bold rounded text-white cursor-pointer flex justify-between items-center">{{ title }} <i
                class="fas fa-sort-down"></i>
        </div>
        <div class="mt-4 border-2 p-2 rounded flex flex-col absolute" :class="isOpen ? 'd-block' : 'hidden'" style="    width: 215px;
            right: 0;
            background-color: #dfdfdf">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false
        }
    },
    name: 'Accordion',
    props: {
        title: String,
    }
}
</script>
